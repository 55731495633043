import React, { useEffect, useState } from 'react';

//pseudotypes
// import { productTypes } from '../../../utils/types/productTypes';
//components
import { DateWrapper } from '../../../components/datepickerWrapper/style';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
//charts
import Chart from 'react-apexcharts';
import columnChartData from '../../../utils/charts/columnChartData';
import { Button, Card, Col, Dropdown, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { api } from '../../../api/api';
import { useSessionStorage } from '@uidotdev/usehooks';
import PiechartCard from '../../../components/piechartCard/piechartCard';

const Products = () => {
	const [isLoading, setLoading] = useSessionStorage('isLoading', false);

	const [servicesFilters, setServicesFilters] = useState({});
	const [servicesChannelFilter, setServicesChannelFilter] = useState('Todos');
	const [filterServices, setFilterServices] = useState('2023');

	const [startDate, setStartDate] = useState(new Date(`2023-01-02`));
	const [endDate, setEndDate] = useState(new Date());

	const {
		data: productServicesQuantities,
		refetch: refetchProductServicesQuantities,
		isLoading: productServicesQuantitiesIsLoading,
		isFetching: productServicesQuantitiesIsFetching,
	} = useQuery(['productServicesQuantities'], {
		queryFn: async () => {
			setLoading(true);
			//convertendo objeto em string params para requisição
			var filters = '';
			const productFilter = { channel: servicesFilters.channel };
			for (let index = 0; index < Object.keys(productFilter).length; index++) {
				const obj = Object.entries(productFilter)[index];
				filters = filters.concat(obj.toString().replace(',', '=') + '&');
			}
			const initialDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
				.toString()
				.padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
			const finalDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
				.toString()
				.padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`;

			const str = `initialDate=${initialDate}&finalDate=${finalDate}`;
			//requisição
			const response = await api.get(`/services/quantities/product?${filters}${str}`);
			setLoading(false);
			return response?.data?.servicesQuantityByProduct.map((service) => ({
				product: service.product || 'Sem Categoria',
				quantity: Number(service.quantity),
			}));
		},
		onError: (err) => console.log(err),
	});

	const handleChangeDateFilter = () => {
		setEndDate(new Date());
		var date = new Date('2018-01-02');
		var finalDate = new Date('2019-12-31');

		switch (filterServices) {
			case '2018':
				date.setFullYear(2018);
				finalDate.setDate(2018);
				break;
			case '2019':
				date.setFullYear(2019);
				finalDate.setFullYear(2019);
				break;
			case '2020':
				date.setFullYear(2020);
				finalDate.setFullYear(2020);
				break;
			case '2021':
				date.setFullYear(2021);
				finalDate.setFullYear(2021);
				break;
			case '2022':
				date.setFullYear(2022);
				finalDate.setFullYear(2022);
				break;
			case '2023':
				date.setFullYear(2023);
				finalDate.setFullYear(2023);
				break;
			case '2024':
				date.setFullYear(2024);
				finalDate.setFullYear(2024);
				break;

			default:
				date.setFullYear(2024);
				finalDate.setFullYear(2024);
				break;
		}
		setStartDate(date);
		setEndDate(finalDate);
	};

	const handleChangeServicesFilters = (filter) => {
		if (!filter) return;

		if (filter === 'all') {
			setServicesFilters({});
			setServicesChannelFilter('Todos');
			return;
		}
		var newFilters = servicesFilters;
		newFilters[Object.keys(filter)[0]] = Object.values(filter)[0];
		setServicesFilters(newFilters);
	};

	//useEffects
	useEffect(() => {
		handleChangeDateFilter();
	}, [filterServices]);
	useEffect(() => {
		refetchProductServicesQuantities();
	}, [servicesChannelFilter, startDate, endDate]);

	return (
		<Col>
			<Card>
				<Card.Body>
					<div
						className='header-title d-flex justify-content-between align-items-center'
						style={{ width: '100%' }}
					>
						<h4 className='card-title'>Atendimentos por produtos</h4>
					</div>

					<div className='d-flex align-items-center' style={{ gap: '1rem' }}>
						<Dropdown>
							<b>Período: </b>
							<Dropdown.Toggle
								as={Button}
								variant='text-gray'
								type='button'
								id='dropdownMenuButtonSM'
							>
								{filterServices}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => setFilterServices('2018')}>2018</Dropdown.Item>
								<Dropdown.Item onClick={() => setFilterServices('2019')}>2019</Dropdown.Item>
								<Dropdown.Item onClick={() => setFilterServices('2020')}>2020</Dropdown.Item>
								<Dropdown.Item onClick={() => setFilterServices('2021')}>2021</Dropdown.Item>
								<Dropdown.Item onClick={() => setFilterServices('2022')}>2022</Dropdown.Item>
								<Dropdown.Item onClick={() => setFilterServices('2023')}>2023</Dropdown.Item>
								<Dropdown.Item onClick={() => setFilterServices('2024')}>2024</Dropdown.Item>
								<Dropdown.Item onClick={() => setFilterServices('Personalizado')}>
									Personalizado
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						<DateWrapper>
							<DatePicker
								locale='pt-BR'
								dateFormat='dd/MM/yyyy'
								placeholderText='Data inicial'
								selectsEnd
								selected={startDate}
								startDate={startDate}
								endDate={endDate}
								onChange={(update) => {
									setFilterServices('Personalizado');
									setStartDate(update);
								}}
								showMonthDropdown
							/>
						</DateWrapper>
						até
						<DateWrapper>
							<DatePicker
								locale='pt-BR'
								dateFormat='dd/MM/yyyy'
								placeholderText='Data final'
								selectsStart
								selected={endDate}
								startDate={startDate}
								endDate={endDate}
								minDate={startDate}
								onChange={(update) => {
									setFilterServices('Personalizado');
									setEndDate(update);
								}}
								showMonthDropdown
							/>
						</DateWrapper>
						<Dropdown>
							<b>Canal: </b>
							<Dropdown.Toggle
								as={Button}
								variant='text-gray'
								type='button'
								id='dropdownMenuButtonSM'
							>
								{servicesChannelFilter}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item
									onClick={() =>
										handleChangeServicesFilters('all', setServicesChannelFilter('Todos'))
									}
								>
									Todos
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => (
										handleChangeServicesFilters({ channel: 'os' }), setServicesChannelFilter('OS')
									)}
								>
									OS
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => (
										handleChangeServicesFilters({ channel: 'ligacao' }),
										setServicesChannelFilter('Ligação')
									)}
								>
									Ligação
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</Card.Body>
			</Card>

			<Card>
				<PiechartCard
					title='Produtos'
					tooltip='Atendimentos por produto.'
					data={productServicesQuantities}
					labelAttribute='product'
					quantityAttribute='quantity'
				/>
			</Card>
		</Col>
	);
};

export default Products;
