import React, { useEffect, useState, useCallback, useDeferredValue } from 'react';
import { api } from '../../../api/api';
import { Link } from 'react-router-dom';

//hooks
import { useQuery, useMutation } from 'react-query';
import { useSessionStorage } from '@uidotdev/usehooks';
import { usePagination } from '@table-library/react-table-library/pagination';

//style
import { PaginationButton } from '../../../components/table/style';
//components
import Tabs, { Tab } from 'react-best-tabs';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from '@table-library/react-table-library/table';
import {
	Col,
	Form,
	Spinner,
	Dropdown,
} from 'react-bootstrap';
// import * as RBRow from 'react-bootstrap/Row';
import Card from '../../../components/card/Card';
import { Modal, Button } from 'react-bootstrap';
import { TableWrapper, TabsWrapper } from './style';
import { useSort, HeaderCellSort } from '@table-library/react-table-library/sort';
import { LuArrowLeftToLine, LuArrowRightToLine, LuArrowRight, LuArrowLeft } from 'react-icons/lu';
import ReactPlaceholder from 'react-placeholder';
import { AttendantDetails } from '../../../components/attendantDetails/attendantDetails';
import Searchbox from '../../../components/searchbox/searchbox';
import Chart from 'react-apexcharts';
import columnChartData from '../../../utils/charts/columnChartData';
// import { productTypes } from '../../../utils/types/productTypes';
import CustomTooltip from '../../../components/customTooltip/CustomTooltip';

const channelMap = {
	chat: 'Chat',
	os: 'OS',
};

const visualizationTypes = {
	table: 'Tabela',
	chart: 'Grafico',
}

const AttendantDash = () => {
	const [isLoading, setLoading] = useSessionStorage('loading');
	//components
	const [visualization, setVisualization] = useState('table');
	const [productTypes, setProductTypes] = useState({});

	const [totalPersons, setTotalPersons] = useState();
	const [showAttendantDetails, setShowAttendantDetails] = useState(false);
	const [attendant, setAttendant] = useState(true);
	const [attendantId, setAttendantId] = useState(1);
	const [totalYears, setTotalYears] = useState([
		'2018',
		'2019',
		'2018',
		'2020',
		'2021',
		'2022',
		'2023',
		'2024',
	]);
	//table
	const [dataTable, setDataTable] = useState();
	const deferredDataTable = useDeferredValue(dataTable);
	const [limitTable, setLimitTable] = useState(20);
	const [yearFilter, setYearFilter] = useState(2024);
	const [MonthFilter, setMonthFilter] = useState(2024);
	const [filterProduct, setFilterProduct] = useState('');
	const [channelFilter, setChannelFilter] = useState('');

	const onPaginationChange = (action, state) => {
		// console.log(action, state);
	};
	const pagination = usePagination(dataTable, {
		state: {
			page: 0,
			size: limitTable,
		},
		onChange: onPaginationChange,
	});

	const { data: productTypesVar, error } = useQuery('productTypesAttendDash', async () => {
		const response = await api.get('/products');
		setProductTypes(response.data);
	});

	const { data: attendants, refetch: refetchAttendants } = useQuery({
		queryKey: 'attendants',
		queryFn: async () => {
			const response = await api.get(
				`/employees/quantity/year?year=${yearFilter}&channel=${channelFilter}&product=${filterProduct}`
			);

			const nodes = response?.data?.servicesQuantity;
			const years = response?.data?.totalYearsList ?? [];
			setTotalPersons(nodes);
			setDataTable({ nodes: nodes });
			setTotalYears(years);
			return response.data;
		},
	});

	useEffect(() => {
		refetchAttendants();
	}, [yearFilter, channelFilter, filterProduct]);

	const sort = useSort(
		dataTable,
		{
			state: {
				sortKey: 'quantity',
				reverse: true,
			},
		},
		{
			sortIcon: {
				iconDefault: null,
				iconUp: null,
				iconDown: null,
			},
			sortFns: {
				name: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
				quantity: (array) => array.sort((a, b) => a.quantity - b.quantity),
			},
		}
	);

	useEffect(() => {
		setAttendant(showAttendantDetails.name);
	}, [setShowAttendantDetails]);

	const handleGetAttendant = (id) => {
		setAttendantId(id);
		setShowAttendantDetails(true);
	};

	// //caso a seleção tenha sido pelo gráfico
	// useEffect(() => {
	//   if (showAttendantDetails?.selected)
	//     attendants?.servicesQuantity.map((service) => {
	//       if (service.name === setShowAttendantDetails?.selected) {
	//         setAttendant(service);
	//       }
	//     });
	// }, [showAttendantDetails]);

	return (
		<>
			<div>
				<div className='d-flex'>
					<Col sm='12'>
						<Card>
							<Card.Body>
								<div className='d-flex flex-column' style={{ gap: '2rem' }}>
									<div
										className='header-title d-flex justify-content-between align-items-center'
										style={{ width: '100%' }}
									>
										<h4 className='card-title'>Atendentes</h4>
										<Searchbox
											list={totalPersons}
											setResult={setDataTable}
											atributeToSearch={'name'}
										/>
									</div>

									<div className='d-flex flex-column'>
										<div style={{ marginLeft: '1rem' }}>
											Total de encontrados:{' '}
											<ReactPlaceholder
												type='text'
												rows={1}
												ready={deferredDataTable}
												showLoadingAnimation={true}
												color='#E0E0E0'
												style={{ width: '50px', display: 'inline-block' }}
											>
												{dataTable?.nodes?.length}
											</ReactPlaceholder>
										</div>
										<div className='d-flex justify-content-between'>
											<div className='d-flex'>
											<Dropdown>
													Visualização:
													<Dropdown.Toggle
														as={Button}
														variant='text-gray'
														type='button'
														id='dropdownMenuButtonSM'
													>
														{visualizationTypes[visualization]}
														</Dropdown.Toggle>

														<Dropdown.Menu>
														<Dropdown.Item onClick={() => setVisualization('table')}>
															Tabela
														</Dropdown.Item>
														<Dropdown.Item onClick={() => setVisualization('chart')}>
															Gráfico
														</Dropdown.Item>
														</Dropdown.Menu>
														</Dropdown>
												<Dropdown>
													Período:
													<Dropdown.Toggle
														as={Button}
														variant='text-gray'
														type='button'
														id='dropdownMenuButtonSM'
													>
														{yearFilter ? yearFilter : 'Todos'}
													</Dropdown.Toggle>
													<Dropdown.Menu>
														{/* <Dropdown.Item onClick={() => setYearFilter('Todos')}>
                              Todos
                            </Dropdown.Item> */}
														{/* <Dropdown.Item onClick={() => setYearFilter('Mês')}>Mês</Dropdown.Item> */}
														<Dropdown.Divider />
														{totalYears.length > 0
															? ['Todos'].concat(totalYears)
																	.sort()
																	.reverse()
																	.map((year) => (
																		<Dropdown.Item onClick={() => year !== 'Todos' ? setYearFilter(year) : setYearFilter('')}>
																			{year}
																		</Dropdown.Item>
																	))
															: null}
													</Dropdown.Menu>
												</Dropdown>
												<Dropdown>
													Produto:
													<Dropdown.Toggle
														as={Button}
														variant='text-gray'
														type='button'
														id='dropdownMenuButtonSM'
													>
														{productTypes[filterProduct] ?? 'Todos'}
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item onClick={() => setFilterProduct('')}>
															Todos
														</Dropdown.Item>
														{Object.keys(productTypes).map((productKey) => (
															<Dropdown.Item onClick={() => setFilterProduct(productKey)}>
																{productTypes[productKey]}
															</Dropdown.Item>
														))}
													</Dropdown.Menu>
												</Dropdown>
												<Dropdown>
													Canal:
													<Dropdown.Toggle
														as={Button}
														variant='text-gray'
														type='button'
														id='dropdownMenuButtonSM'
													>
														{channelMap[channelFilter] ?? 'Todos'}
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item onClick={() => setChannelFilter('')}>
															Todos
														</Dropdown.Item>
														<Dropdown.Item onClick={() => setChannelFilter('chat')}>
															Chat
														</Dropdown.Item>
														<Dropdown.Item onClick={() => setChannelFilter('os')}>OS</Dropdown.Item>
														<Dropdown.Item onClick={() => setChannelFilter('ligacao')}>
															Ligação
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</div>
											<div
												className='d-flex align-items-center'
												style={{ marginLeft: '1rem', gap: '1rem' }}
											>
												<Form.Label htmlFor='shows'>Mostrar:</Form.Label>
												<Form.Select
													id='shows'
													style={{ width: 'max-content' }}
													onChange={(e) => setLimitTable(e.target.value)}
												>
													<option value='20'>20</option>
													<option value='50'>50</option>
													<option value='100'>100</option>
													<option value='500'>500</option>
												</Form.Select>
											</div>
										</div>
									</div>
								</div>
							</Card.Body>
						</Card>

						<Card>
							<Card.Body className='px-0'>
								{visualization === 'table' && (
									<div className='table-responsive'>
												{deferredDataTable ? (
													<>
														<div style={{ textAlign: 'end' }}>
															<PaginationButton
																type='button'
																disabled={pagination.state.page === 0}
																onClick={() => pagination.fns.onSetPage(0)}
															>
																<LuArrowLeftToLine />
															</PaginationButton>
															<PaginationButton
																type='button'
																disabled={pagination.state.page === 0}
																onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
															>
																<LuArrowLeft />
															</PaginationButton>
															<PaginationButton
																type='button'
																disabled={
																	pagination.state.page + 1 >=
																	pagination.state.getTotalPages(dataTable.nodes)
																}
																onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
															>
																<LuArrowRight />
															</PaginationButton>
															<PaginationButton
																type='button'
																disabled={
																	pagination.state.page + 1 >=
																	pagination.state.getTotalPages(dataTable.nodes)
																}
																onClick={() =>
																	pagination.fns.onSetPage(
																		pagination.state.getTotalPages(dataTable.nodes) - 1
																	)
																}
															>
																<LuArrowRightToLine />
															</PaginationButton>
														</div>
														<TableWrapper>
															<Table
																data={deferredDataTable}
																sort={sort}
																pagination={pagination}
																id='user-list-table'
																className='table m-auto'
																role='grid'
																data-toggle='data-table'
																style={{ width: 'max-content' }}
															>
																{(tableList) => (
																	<>
																		<Header>
																			<HeaderRow>
																				<HeaderCellSort sortKey='name'>Nome</HeaderCellSort>
																				<HeaderCellSort sortKey='quantity'>
																				<div className='d-flex'>
																					<span className="me-2">Atendimentos Realizados</span>
																						<CustomTooltip
																							text={
																								<div style={{textAlign: 'left'}}>
																									<p>Total de atendimentos realizados no ano</p>
																								</div>
																							}
																						/>
																					</div>
																				</HeaderCellSort>
																				<HeaderCellSort sortKey='seen'>
																					<div className='d-flex'>
																						<span className='me-2'>Visualizados</span>
																						<CustomTooltip
																							text={
																								<div style={{textAlign: 'left'}}>
																									<p>Atendimentos visualizados porém aguardando retorno. Dados cumulativos, atualizados a cada 24h.</p>
																								</div>
																							}
																						/>
																					</div>
																				</HeaderCellSort>
																			</HeaderRow>
																		</Header>

																		<Body>
																			{tableList?.length > 0 ? (
																				tableList.map((item, index) => (
																					<Row key={index} item={item}>
																						<Cell
																							onClick={() => (
																								handleGetAttendant(item?.id), setAttendant(item)
																							)}
																							style={{ cursor: 'pointer' }}
																						>
																							{item.name}
																						</Cell>
																						<Cell>{item.quantity}</Cell>
																						<Cell>{item.seen}</Cell>
																					</Row>
																				))
																			) : (
																				<Row>
																					<Cell style={{ width: '121%' }}>
																						Não há dados sobre os atendentes correpondente a este
																						filtro
																					</Cell>
																				</Row>
																			)}
																		</Body>
																	</>
																)}
															</Table>
														</TableWrapper>
														<div className='d-flex justify-content-between'>
															<div style={{ marginLeft: '1rem' }}>
																Páginas: {pagination.state.page + 1}/
																{pagination.state.getTotalPages(dataTable.nodes)}
															</div>

															<div style={{ textAlign: 'end' }}>
																Passar:{' '}
																<PaginationButton
																	type='button'
																	disabled={pagination.state.page === 0}
																	onClick={() => pagination.fns.onSetPage(0)}
																>
																	<LuArrowLeftToLine />
																</PaginationButton>
																<PaginationButton
																	type='button'
																	disabled={pagination.state.page === 0}
																	onClick={() =>
																		pagination.fns.onSetPage(pagination.state.page - 1)
																	}
																>
																	<LuArrowLeft />
																</PaginationButton>
																<PaginationButton
																	type='button'
																	disabled={
																		pagination.state.page + 1 >=
																		pagination.state.getTotalPages(dataTable.nodes)
																	}
																	onClick={() =>
																		pagination.fns.onSetPage(pagination.state.page + 1)
																	}
																>
																	<LuArrowRight />
																</PaginationButton>
																<PaginationButton
																	type='button'
																	disabled={
																		pagination.state.page + 1 >=
																		pagination.state.getTotalPages(dataTable.nodes)
																	}
																	onClick={() =>
																		pagination.fns.onSetPage(
																			pagination.state.getTotalPages(dataTable.nodes) - 1
																		)
																	}
																>
																	<LuArrowRightToLine />
																</PaginationButton>
															</div>
														</div>
													</>
												) : (
													<Spinner />
												)}
											</div>
								)}

								{visualization === 'chart' && (
									attendants?.servicesQuantity ? (
												<Chart
													options={
														columnChartData(
															attendants?.servicesQuantity,
															'name',
															true
															// setShowAttendantDetails
														).options
													}
													series={
														columnChartData(
															attendants?.servicesQuantity,
															'name',
															true
															// setShowAttendantDetails
														).series
													}
													type='bar'
													height='1200'
												/>
											) : (
												<Spinner />
											)
								)}
							</Card.Body>
						</Card>
					</Col>
				</div>
			</div>

			<AttendantDetails
				show={showAttendantDetails}
				attendant={attendant}
				setShowAttendantDetails={setShowAttendantDetails}
				totalYears={totalYears}
				year={String(yearFilter) || new Date().getFullYear().toString()}
				channel={channelFilter}
				product={filterProduct}
			/>
		</>
	);
};

export default AttendantDash;
