import React, { useState, useContext, memo, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';

import jwtDecode from 'jwt-decode';

import { Accordion, useAccordionButton, AccordionContext } from 'react-bootstrap';

import { LuCode2 } from 'react-icons/lu';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { RiCustomerService2Fill } from 'react-icons/ri';
import { IoLogoWhatsapp } from 'react-icons/io';

const CustomToggle = ({ children, eventKey, onClick }) => {
	const { activeEventKey } = useContext(AccordionContext);

	const decoratedOnClick = useAccordionButton(eventKey, (active) =>
		onClick({ state: !active, eventKey: eventKey })
	);

	const isCurrentEventKey = activeEventKey === eventKey;

	return (
		<Link
			to='#'
			aria-expanded={isCurrentEventKey ? 'true' : 'false'}
			className='nav-link'
			role='button'
			onClick={(e) => {
				decoratedOnClick(isCurrentEventKey);
			}}
		>
			{children}
		</Link>
	);
};

const VerticalNav = memo((props) => {
	const [activeMenu, setActiveMenu] = useState(false);
	const [active, setActive] = useState('');

	const decoded = jwtDecode(JSON.parse(localStorage.getItem('@datahub-token'))?.token);

	//location
	let location = useLocation();

	const handleGetPermission = (permission) => {
		return permission.includes(decoded?.permission) || permission === 'all' ? 'initial' : 'none';
	};

	return (
		<Fragment>
			<Accordion as='ul' className='navbar-nav iq-main-menu'>
				<div style={{ display: handleGetPermission(['D']) }}>
					<li className='nav-item static-item'>
						<Link className='nav-link static-item disabled' to='#' tabIndex='-1'>
							<span className='default-icon'>Home</span>
							<span className='mini-icon'>-</span>
						</Link>
					</li>
					<li
						className={`${
							location.pathname === '/dashboard' || location.pathname === '/' ? 'active' : ''
						} nav-item `}
					>
						<Link
							className={`${
								location.pathname === '/dashboard' || location.pathname === '/' ? 'active' : ''
							} nav-link `}
							aria-current='page'
							to='/dashboard'
							onClick={() => {}}
						>
							<i className='icon'>
								<svg width='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										opacity='0.4'
										d='M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z'
										fill='currentColor'
									></path>
									<path
										fillRule='evenodd'
										clipRule='evenodd'
										d='M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z'
										fill='currentColor'
									></path>
								</svg>
							</i>
							<span className='item-name'>Painel Geral</span>
						</Link>
					</li>

					<li>
						<hr className='hr-horizontal' />
					</li>
				</div>

				<li className='nav-item static-item'>
					<Link className='nav-link static-item disabled' to='#' tabIndex='-1'>
						<span className='default-icon'>Visões</span>
						<span className='mini-icon'>-</span>
					</Link>
				</li>

				<Accordion.Item
					as='li'
					eventKey='recursos-humanos'
					bsPrefix={`nav-item ${active === 'recursos' ? 'active' : ''} `}
					onClick={() => setActive('recursos')}
					style={{ display: handleGetPermission(['PM', 'D']) }}
				>
					<CustomToggle
						eventKey='recursos-humanos'
						active={activeMenu === 'recursos-humanos' ? true : false}
						onClick={(activeKey) => setActiveMenu(activeKey)}
					>
						<i className='icon'>
							<svg width='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M11.9488 14.54C8.49884 14.54 5.58789 15.1038 5.58789 17.2795C5.58789 19.4562 8.51765 20.0001 11.9488 20.0001C15.3988 20.0001 18.3098 19.4364 18.3098 17.2606C18.3098 15.084 15.38 14.54 11.9488 14.54Z'
									fill='currentColor'
								></path>
								<path
									opacity='0.4'
									d='M11.949 12.467C14.2851 12.467 16.1583 10.5831 16.1583 8.23351C16.1583 5.88306 14.2851 4 11.949 4C9.61293 4 7.73975 5.88306 7.73975 8.23351C7.73975 10.5831 9.61293 12.467 11.949 12.467Z'
									fill='currentColor'
								></path>
								<path
									opacity='0.4'
									d='M21.0881 9.21923C21.6925 6.84176 19.9205 4.70654 17.664 4.70654C17.4187 4.70654 17.1841 4.73356 16.9549 4.77949C16.9244 4.78669 16.8904 4.802 16.8725 4.82902C16.8519 4.86324 16.8671 4.90917 16.8895 4.93889C17.5673 5.89528 17.9568 7.0597 17.9568 8.30967C17.9568 9.50741 17.5996 10.6241 16.9728 11.5508C16.9083 11.6462 16.9656 11.775 17.0793 11.7948C17.2369 11.8227 17.3981 11.8371 17.5629 11.8416C19.2059 11.8849 20.6807 10.8213 21.0881 9.21923Z'
									fill='currentColor'
								></path>
								<path
									d='M22.8094 14.817C22.5086 14.1722 21.7824 13.73 20.6783 13.513C20.1572 13.3851 18.747 13.205 17.4352 13.2293C17.4155 13.232 17.4048 13.2455 17.403 13.2545C17.4003 13.2671 17.4057 13.2887 17.4316 13.3022C18.0378 13.6039 20.3811 14.916 20.0865 17.6834C20.074 17.8032 20.1698 17.9068 20.2888 17.8888C20.8655 17.8059 22.3492 17.4853 22.8094 16.4866C23.0637 15.9589 23.0637 15.3456 22.8094 14.817Z'
									fill='currentColor'
								></path>
								<path
									opacity='0.4'
									d='M7.04459 4.77973C6.81626 4.7329 6.58077 4.70679 6.33543 4.70679C4.07901 4.70679 2.30701 6.84201 2.9123 9.21947C3.31882 10.8216 4.79355 11.8851 6.43661 11.8419C6.60136 11.8374 6.76343 11.8221 6.92013 11.7951C7.03384 11.7753 7.09115 11.6465 7.02668 11.551C6.3999 10.6234 6.04263 9.50765 6.04263 8.30991C6.04263 7.05904 6.43303 5.89462 7.11085 4.93913C7.13234 4.90941 7.14845 4.86348 7.12696 4.82926C7.10906 4.80135 7.07593 4.78694 7.04459 4.77973Z'
									fill='currentColor'
								></path>
								<path
									d='M3.32156 13.5127C2.21752 13.7297 1.49225 14.1719 1.19139 14.8167C0.936203 15.3453 0.936203 15.9586 1.19139 16.4872C1.65163 17.4851 3.13531 17.8066 3.71195 17.8885C3.83104 17.9065 3.92595 17.8038 3.91342 17.6832C3.61883 14.9167 5.9621 13.6046 6.56918 13.3029C6.59425 13.2885 6.59962 13.2677 6.59694 13.2542C6.59515 13.2452 6.5853 13.2317 6.5656 13.2299C5.25294 13.2047 3.84358 13.3848 3.32156 13.5127Z'
									fill='currentColor'
								></path>
							</svg>
						</i>
						<span className='item-name'>Recursos H.</span>
						<i className='right-icon'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='18'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M9 5l7 7-7 7'
								/>
							</svg>
						</i>
					</CustomToggle>

					<Accordion.Collapse eventKey='recursos-humanos'>
						<ul className='sub-nav'>
							<li className={`${location.pathname === '/dashboard/rh' ? 'active' : ''} nav-item `}>
								<Link
									className={`${location.pathname === '/dashboard/rh' ? 'active' : ''} nav-link `}
									aria-current='page'
									to='/dashboard/rh'
									onClick={() => {}}
								>
									<i className='icon'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											viewBox='0 0 24 24'
											fill='currentColor'
										>
											<g>
												<circle cx='12' cy='12' r='8' fill='currentColor'></circle>
											</g>
										</svg>
									</i>
									<i className='sidenav-mini-icon'>-</i>
									<span className='item-name'>Visão Geral</span>
								</Link>
							</li>
						</ul>
					</Accordion.Collapse>
				</Accordion.Item>

				<li
					className={`${location.pathname === '/dashboard/developers' ? 'active' : ''} nav-item `}
					style={{ display: handleGetPermission(['D']) }}
				>
					<Link
						className={`${location.pathname === '/dashboard/developers' ? 'active' : ''} nav-link `}
						aria-current='page'
						to='/dashboard/developers'
						onClick={() => {}}
					>
						<i className='icon'>
							<LuCode2 />
						</i>
						<span className='item-name'>Desenvolvedores</span>
					</Link>
				</li>

				<Accordion.Item
					as='li'
					eventKey='services-sub'
					bsPrefix={`nav-item ${active === 'services-sub' ? 'active' : ''} `}
					onClick={() => setActive('services-sub')}
					style={{ display: handleGetPermission(['D']) }}
				>
					<CustomToggle
						eventKey='services-sub'
						active={activeMenu === 'services-sub' ? true : false}
						onClick={(activeKey) => setActiveMenu(activeKey)}
					>
						<i className='icon'>
							<svg width='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M11.5317 12.4724C15.5208 16.4604 16.4258 11.8467 18.9656 14.3848C21.4143 16.8328 22.8216 17.3232 19.7192 20.4247C19.3306 20.737 16.8616 24.4943 8.1846 15.8197C-0.493478 7.144 3.26158 4.67244 3.57397 4.28395C6.68387 1.17385 7.16586 2.58938 9.61449 5.03733C12.1544 7.5765 7.54266 8.48441 11.5317 12.4724Z'
									fill='currentColor'
								/>
							</svg>
						</i>
						<span className='item-name'>Atendimentos</span>
						<i className='right-icon'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='18'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M9 5l7 7-7 7'
								/>
							</svg>
						</i>
					</CustomToggle>
					<Accordion.Collapse eventKey='services-sub'>
						<ul className='sub-nav'>
							<li
								className={`${
									location.pathname === '/dashboard/services' ? 'active' : ''
								} nav-item `}
							>
								<Link
									className={`${
										location.pathname === '/dashboard/services' ? 'active' : ''
									} nav-link `}
									to='/dashboard/services'
								>
									<i className='icon'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											viewBox='0 0 24 24'
											fill='currentColor'
										>
											<g>
												<circle cx='12' cy='12' r='8' fill='currentColor'></circle>
											</g>
										</svg>
									</i>
									<i className='sidenav-mini-icon'>-</i>
									<span className='item-name'>Visão geral</span>
								</Link>
							</li>

							<li
								className={`${
									location.pathname === '/dashboard/services/clients' ? 'active' : ''
								} nav-item `}
							>
								<Link
									className={`${
										location.pathname === '/dashboard/services/clients' ? 'active' : ''
									} nav-link `}
									to='/dashboard/services/clients'
								>
									<i className='icon'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											viewBox='0 0 24 24'
											fill='currentColor'
										>
											<g>
												<circle cx='12' cy='12' r='8' fill='currentColor'></circle>
											</g>
										</svg>
									</i>
									<i className='sidenav-mini-icon'>-</i>
									<span className='item-name'>Por clientes</span>
								</Link>
							</li>

							<li
								className={`${
									location.pathname === '/dashboard/services/products' ? 'active' : ''
								} nav-item `}
							>
								<Link
									className={`${
										location.pathname === '/dashboard/services/products' ? 'active' : ''
									} nav-link `}
									to='/dashboard/services/products'
								>
									<i className='icon'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											viewBox='0 0 24 24'
											fill='currentColor'
										>
											<g>
												<circle cx='12' cy='12' r='8' fill='currentColor'></circle>
											</g>
										</svg>
									</i>
									<i className='sidenav-mini-icon'>-</i>
									<span className='item-name'>Por produtos</span>
								</Link>
							</li>

							<li
								className={`${
									location.pathname === '/dashboard/services/attendants' ? 'active' : ''
								} nav-item `}
							>
								<Link
									className={`${
										location.pathname === '/dashboard/services/attendants' ? 'active' : ''
									} nav-link `}
									to='/dashboard/services/attendants'
								>
									<i className='icon'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											viewBox='0 0 24 24'
											fill='currentColor'
										>
											<g>
												<circle cx='12' cy='12' r='8' fill='currentColor'></circle>
											</g>
										</svg>
									</i>
									<i className='sidenav-mini-icon'>-</i>
									<span className='item-name'>Atendentes</span>
								</Link>
							</li>

							<li className='nav-item'>
								<Link
									className={`${location.pathname === '/dashboard/flow' ? 'active' : ''} nav-link`}
									to='/dashboard/flow'
								>
									<i className='icon'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											viewBox='0 0 24 24'
											fill='currentColor'
										>
											<g>
												<circle cx='12' cy='12' r='8' fill='currentColor'></circle>
											</g>
										</svg>
									</i>
									<i className='sidenav-mini-icon'>-</i>
									<span className='item-name'>Fluxograma</span>
								</Link>
							</li>

							<li className='nav-item'>
								<Link
									className={`${
										location.pathname === '/dashboard/call-center' ? 'active' : ''
									} nav-link`}
									to='/dashboard/call-center'
								>
									<i className='icon'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											viewBox='0 0 24 24'
											fill='currentColor'
										>
											<g>
												<circle cx='12' cy='12' r='8' fill='currentColor'></circle>
											</g>
										</svg>
									</i>
									<i className='sidenav-mini-icon'>-</i>
									<span className='item-name'>Call center</span>
								</Link>
							</li>
						</ul>
					</Accordion.Collapse>
				</Accordion.Item>

				{/* Esperando aprovação */}
				<Accordion.Item
					as='li'
					eventKey='call-center-sub'
					bsPrefix={`nav-item ${active === 'call-center-sub' ? 'active' : ''} `}
					onClick={() => setActive('call-center-sub')}
					style={{ display: handleGetPermission(['D']) }}
				>
					<CustomToggle
						eventKey='call-center-sub'
						active={activeMenu === 'call-center-sub' ? true : false}
						onClick={(activeKey) => setActiveMenu(activeKey)}
					>
						<i className='icon'>
							<RiCustomerService2Fill size={18} />
						</i>
						<span className='item-name'>Call Center</span>
						<i className='right-icon'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='18'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M9 5l7 7-7 7'
								/>
							</svg>
						</i>
					</CustomToggle>
					<Accordion.Collapse eventKey='call-center-sub'>
						<ul className='sub-nav'>
							<li className='nav-item'>
								<Link
									className={`${
										location.pathname === '/dashboard/call-center' ? 'active' : ''
									} nav-link`}
									to='/dashboard/call-center'
								>
									<i className='icon'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											viewBox='0 0 24 24'
											fill='currentColor'
										>
											<g>
												<circle cx='12' cy='12' r='8' fill='currentColor'></circle>
											</g>
										</svg>
									</i>
									<i className='sidenav-mini-icon'>-</i>
									<span className='item-name'>Visão geral</span>
								</Link>
							</li>

							<li className='nav-item'>
								<Link
									className={`${
										location.pathname === '/dashboard/call-center/details' ? 'active' : ''
									} nav-link`}
									to='/dashboard/call-center/details'
								>
									<i className='icon'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											viewBox='0 0 24 24'
											fill='currentColor'
										>
											<g>
												<circle cx='12' cy='12' r='8' fill='currentColor'></circle>
											</g>
										</svg>
									</i>
									<i className='sidenav-mini-icon'>-</i>
									<span className='item-name'>Detalhes</span>
								</Link>
							</li>
						</ul>
					</Accordion.Collapse>
				</Accordion.Item>
				{/* Esperando aprovação */}
				{/* <li className='nav-item'>
					<Link
						className={`${location.pathname === '/dashboard/financial' ? 'active' : ''} nav-link`}
						to='/dashboard/financial'
					>
						<i className='icon'>
							<AiOutlineDollarCircle size={18} />
						</i>
						<span className='item-name'>Financeiro</span>
					</Link>
				</li> */}

				<li className='nav-item'>
					<Link
						className={`${
							location.pathname === '/dashboard/services/whatsapp' ? 'active' : ''
						} nav-link`}
						to='/dashboard/services/whatsapp'
					>
						<i className='icon'>
							<IoLogoWhatsapp size={18} />
						</i>
						<i className='sidenav-mini-icon'>-</i>
						<span className='item-name'>Whatsapp</span>
					</Link>
				</li>

				<Accordion.Item
					as='li'
					eventKey='utilities-error'
					bsPrefix={`nav-item ${active === 'error' ? 'active' : ''} `}
					onClick={() => setActive('error')}
					style={{ display: handleGetPermission(['D']) }}
				>
					{/* <CustomToggle
						eventKey='utilities-error'
						active={activeMenu === 'utilities-error' ? true : false}
						onClick={(activeKey) => setActiveMenu(activeKey)}
					> */}
						{/* <i className='icon'>
							<svg width='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path
									opacity='0.4'
									d='M16.6756 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0711 3.92889 22 7.33333 22H16.6756C20.08 22 22 20.0711 22 16.6667V7.33333C22 3.92889 20.08 2 16.6756 2Z'
									fill='currentColor'
								/>
								<path
									d='M7.36866 9.3689C6.91533 9.3689 6.54199 9.74223 6.54199 10.2045V17.0756C6.54199 17.5289 6.91533 17.9022 7.36866 17.9022C7.83088 17.9022 8.20421 17.5289 8.20421 17.0756V10.2045C8.20421 9.74223 7.83088 9.3689 7.36866 9.3689Z'
									fill='currentColor'
								/>
								<path
									d='M12.0352 6.08887C11.5818 6.08887 11.2085 6.4622 11.2085 6.92442V17.0755C11.2085 17.5289 11.5818 17.9022 12.0352 17.9022C12.4974 17.9022 12.8707 17.5289 12.8707 17.0755V6.92442C12.8707 6.4622 12.4974 6.08887 12.0352 6.08887Z'
									fill='currentColor'
								/>
								<path
									d='M16.6398 12.9956C16.1775 12.9956 15.8042 13.3689 15.8042 13.8312V17.0756C15.8042 17.5289 16.1775 17.9023 16.6309 17.9023C17.0931 17.9023 17.4664 17.5289 17.4664 17.0756V13.8312C17.4664 13.3689 17.0931 12.9956 16.6398 12.9956Z'
									fill='currentColor'
								/>
							</svg>
						</i> */}
						{/* <span className='item-name'>PMO</span>
						<i className='right-icon'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='18'
								fill='none'
								viewBox='0 0 24 24'
								stroke='currentColor'
							>
								<path
									strokeLinecap='round'
									strokeLinejoin='round'
									strokeWidth='2'
									d='M9 5l7 7-7 7'
								/>
							</svg>
						</i> */}
					{/* </CustomToggle> */}
					<Accordion.Collapse eventKey='utilities-error'>
						<ul className='sub-nav'>
							<li className='nav-item'>
								<Link
									className={`${location.pathname === '/trello/salvador' ? 'active' : ''} nav-link`}
									to='/trello/salvador'
								>
									<i className='icon'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											viewBox='0 0 24 24'
											fill='currentColor'
										>
											<g>
												<circle cx='12' cy='12' r='8' fill='currentColor'></circle>
											</g>
										</svg>
									</i>
									<i className='sidenav-mini-icon'>-</i>
									<span className='item-name'>Salvador</span>
								</Link>
							</li>
							<li className='nav-item'>
								<Link
									className={`${
										location.pathname === '/trello/barra-mansa' ? 'active' : ''
									} nav-link`}
									to='/trello/barra-mansa'
								>
									<i className='icon'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											viewBox='0 0 24 24'
											fill='currentColor'
										>
											<g>
												<circle cx='12' cy='12' r='8' fill='currentColor'></circle>
											</g>
										</svg>
									</i>
									<i className='sidenav-mini-icon'>-</i>
									<span className='item-name'>Barra Mansa</span>
								</Link>
							</li>
						</ul>
					</Accordion.Collapse>
				</Accordion.Item>

				<li>
					<hr className='hr-horizontal' />
				</li>

				<li className='nav-item static-item' style={{ display: handleGetPermission(['D']) }}>
					<Link className='nav-link static-item disabled' to='#' tabIndex='-1'>
						<span className='default-icon'>Sistema</span>
						<span className='mini-icon'>-</span>
					</Link>
				</li>

				<li className='nav-item' style={{ display: handleGetPermission(['D']) }}>
					<Link
						className={`${location.pathname === '/admin/user-list' ? 'active' : ''} nav-link`}
						to='/admin/user-list'
					>
						<i className='icon'>
							<svg width='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M9.34933 14.8577C5.38553 14.8577 2 15.47 2 17.9173C2 20.3665 5.364 20.9999 9.34933 20.9999C13.3131 20.9999 16.6987 20.3876 16.6987 17.9403C16.6987 15.4911 13.3347 14.8577 9.34933 14.8577Z'
									fill='currentColor'
								/>
								<path
									opacity='0.4'
									d='M9.34935 12.5248C12.049 12.5248 14.2124 10.4062 14.2124 7.76241C14.2124 5.11865 12.049 3 9.34935 3C6.65072 3 4.48633 5.11865 4.48633 7.76241C4.48633 10.4062 6.65072 12.5248 9.34935 12.5248Z'
									fill='currentColor'
								/>
								<path
									opacity='0.4'
									d='M16.1733 7.84873C16.1733 9.19505 15.7604 10.4513 15.0363 11.4948C14.961 11.6021 15.0275 11.7468 15.1586 11.7698C15.3406 11.7995 15.5275 11.8177 15.7183 11.8216C17.6165 11.8704 19.3201 10.6736 19.7907 8.87116C20.4884 6.19674 18.4414 3.79541 15.8338 3.79541C15.551 3.79541 15.2799 3.82416 15.0157 3.87686C14.9795 3.88453 14.9404 3.90177 14.9208 3.93244C14.8954 3.97172 14.914 4.02251 14.9394 4.05605C15.7232 5.13214 16.1733 6.44205 16.1733 7.84873Z'
									fill='currentColor'
								/>
								<path
									d='M21.779 15.1693C21.4316 14.4439 20.593 13.9465 19.3171 13.7022C18.7153 13.5585 17.0852 13.3544 15.5695 13.3831C15.547 13.386 15.5343 13.4013 15.5324 13.4109C15.5294 13.4262 15.5363 13.4492 15.5656 13.4655C16.2662 13.8047 18.9737 15.2804 18.6332 18.3927C18.6185 18.5288 18.729 18.6438 18.867 18.6246C19.5333 18.5317 21.2476 18.1704 21.779 17.0474C22.0735 16.4533 22.0735 15.7634 21.779 15.1693Z'
									fill='currentColor'
								/>
							</svg>
						</i>
						<span className='item-name'>Usuários</span>
					</Link>
				</li>

				<li className='nav-item' style={{ display: handleGetPermission(['D']) }}>
					<Link
						className={`${location.pathname === 'errors/documentation' ? 'active' : ''} nav-link`}
						to='/documentation'
					>
						<i className='icon'>
							<svg width='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path
									d='M12.0122 14.8299C10.4077 14.8299 9.10986 13.5799 9.10986 12.0099C9.10986 10.4399 10.4077 9.17993 12.0122 9.17993C13.6167 9.17993 14.8839 10.4399 14.8839 12.0099C14.8839 13.5799 13.6167 14.8299 12.0122 14.8299Z'
									fill='currentColor'
								/>
								<path
									opacity='0.4'
									d='M21.2301 14.37C21.036 14.07 20.76 13.77 20.4023 13.58C20.1162 13.44 19.9322 13.21 19.7687 12.94C19.2475 12.08 19.5541 10.95 20.4228 10.44C21.4447 9.87 21.7718 8.6 21.179 7.61L20.4943 6.43C19.9118 5.44 18.6344 5.09 17.6226 5.67C16.7233 6.15 15.5685 5.83 15.0473 4.98C14.8838 4.7 14.7918 4.4 14.8122 4.1C14.8429 3.71 14.7203 3.34 14.5363 3.04C14.1582 2.42 13.4735 2 12.7172 2H11.2763C10.5302 2.02 9.84553 2.42 9.4674 3.04C9.27323 3.34 9.16081 3.71 9.18125 4.1C9.20169 4.4 9.10972 4.7 8.9462 4.98C8.425 5.83 7.27019 6.15 6.38109 5.67C5.35913 5.09 4.09191 5.44 3.49917 6.43L2.81446 7.61C2.23194 8.6 2.55897 9.87 3.57071 10.44C4.43937 10.95 4.74596 12.08 4.23498 12.94C4.06125 13.21 3.87729 13.44 3.59115 13.58C3.24368 13.77 2.93709 14.07 2.77358 14.37C2.39546 14.99 2.4159 15.77 2.79402 16.42L3.49917 17.62C3.87729 18.26 4.58245 18.66 5.31825 18.66C5.66572 18.66 6.0745 18.56 6.40153 18.36C6.65702 18.19 6.96361 18.13 7.30085 18.13C8.31259 18.13 9.16081 18.96 9.18125 19.95C9.18125 21.1 10.1215 22 11.3069 22H12.6968C13.872 22 14.8122 21.1 14.8122 19.95C14.8429 18.96 15.6911 18.13 16.7029 18.13C17.0299 18.13 17.3365 18.19 17.6022 18.36C17.9292 18.56 18.3278 18.66 18.6855 18.66C19.411 18.66 20.1162 18.26 20.4943 17.62L21.2097 16.42C21.5776 15.75 21.6083 14.99 21.2301 14.37Z'
									fill='currentColor'
								/>
							</svg>
						</i>
						<span className='item-name'>Documentação</span>
					</Link>
				</li>
				<li className='nav-item static-item'>
					<Link className='nav-link static-item disabled' to='#' tabIndex='-1'>
						<span className='default-icon'>-</span>
						<span className='mini-icon'>-</span>
					</Link>
				</li>
			</Accordion>
		</Fragment>
	);
});

export default VerticalNav;
