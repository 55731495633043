import React, { useState, useEffect, memo, useMemo, useCallback } from 'react';
import { api } from '../../api/api';
//hooks
import { useQuery } from 'react-query';
//charts
import Chart from 'react-apexcharts';
import columnLineData from '../../utils/charts/columnLineData';
//pseudoTypes
// import { productTypes } from '../../utils/types/productTypes';
// AOS animation
import AOS from 'aos';
import 'aos';
import '../../../node_modules/aos/dist/aos.css';
//components
import { Button, Col, Dropdown, Spinner } from 'react-bootstrap';
import CustomTooltip from '../customTooltip/CustomTooltip';
import ReactPlaceholder from 'react-placeholder';
import { useDebounce, useSessionStorage } from '@uidotdev/usehooks';

const chartTypes = {
	Semanal: 7,
	Anual: 12,
};

const StockByYear = memo((props) => {
	//Charts filters
	const [dateFilter, setDateFilter] = useState(`${props.initialYear}` ?? '2024');
	const [fixedPeriod, setFixedPeriod] = useState('');
	const [filtersColumnLineChart, setFiltersColumnLineChart] = useState({});
	const [channelFilter, setChannelFilter] = useState('Todos');
	const [productFilter, setProductFilter] = useState('Todos');
	const [productTypes, setProductTypes] = useState({});

	//queries
	const { data: productTypesVar, error } = useQuery('productTypesServicesOver', async () => {
		const response = await api.get('/products');
		setProductTypes(response.data);
	});

	const {
		data: newXFinished,
		refetch: refetchNewXFinished,
		isLoading: newXFinishedIsLoading,
		isFetching: newXFinishedIsFetching,
	} = useQuery(['newXFinished'], {
		queryFn: async () => {
			//convertendo objeto em string params para requisição
			var str = '';
			for (let index = 0; index < Object.keys(filtersColumnLineChart).length; index++) {
				const obj = Object.entries(filtersColumnLineChart)[index];
				str = str.concat(obj.toString().replace(',', '=') + '&');
			}
			//requisição
			const response = await api.get(
				`/services/new_vs_finished?year=${dateFilter.includes('20') ? dateFilter : '2024'}&${str}`
			);

			return response?.data;
		},
		onError: (err) => console.log(err),
	});
	const debouncedChart = useDebounce(newXFinished, 100);

	const applyDateFilters = useCallback(
		(last7Days, last30Days, last12Months, last3Years, servicesQuantityByMonth) => {
			if (!newXFinished) return { series: [], data: [] };

			const allServices = {
				Semanal: newXFinished[last7Days],
				Mensal: newXFinished[last30Days],
				Anual: newXFinished[last12Months],
				'Últimos anos': newXFinished[last3Years],
			};

			var servicesFiltered = newXFinished[servicesQuantityByMonth].map((data) => {
				if (new Date(data.date).getUTCFullYear() == dateFilter) {
					return data;
				}
			});
			let servicesTemplate = [];
			if (dateFilter.includes('20')) {
				let servicesTemplate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

				var servicesFiltered = newXFinished[servicesQuantityByMonth].map((data) => {
					if (new Date(data.date).getUTCFullYear() == dateFilter) {
						return data;
					}
				});

				for (const key in servicesFiltered) {
					const month = servicesFiltered[key];
					servicesTemplate[new Date(month?.date).getUTCMonth()] = month?.quantity;
				}

				const categoryDays = [
					'Jan',
					'Fev',
					'Mar',
					'Abr',
					'Mai',
					'Jun',
					'Jul',
					'Ago',
					'Set',
					'Out',
					'Nov',
					'Dez',
				];

				return { series: categoryDays, data: servicesTemplate };
			} else if (dateFilter === 'Últimos anos') {
				var servicesFiltered = allServices[dateFilter].map((data) => {
					if (new Date(data.date).getUTCFullYear() === new Date().getUTCFullYear()) {
						return data;
					}
				});
				servicesTemplate = [];

				servicesTemplate = allServices[dateFilter].map((entry) => entry.quantity);

				servicesFiltered = allServices[dateFilter].map((entry) =>
					new Date(entry.date).getUTCFullYear()
				);

				return { series: servicesFiltered, data: servicesTemplate.reverse() };
			} else if (dateFilter === 'Semanal') {
				const today = new Date();

				var days = [];
				// Calcular a data há 7 dias
				var seteDiasAtras = new Date();
				seteDiasAtras.setDate(today.getUTCDate() - 7);

				// Imprimir os últimos 7 dias
				for (var i = 0; i < 7; i++) {
					const day = new Date(seteDiasAtras);
					day.setDate(seteDiasAtras.getUTCDate() + i);
					days.push({ quantity: 0, date: day });
				}

				days.map((day) => {
					allServices[dateFilter].forEach((element) => {
						if (
							day.date.getUTCMonth() === new Date(element.date).getUTCMonth() &&
							day.date.getUTCDate() + 1 === new Date(element.date).getUTCDate()
						) {
							day.quantity = element.quantity;
						}
					});
				});

				return {
					series: days.map((day) => day.date.setDate(day.date.getUTCDate())),
					data: days.map((day) => day.quantity),
				};
			} else {
				// Converter as strings de data para objetos Date
				const dateObjects = allServices[dateFilter].map((item) => ({
					...item,
					date: new Date(item.date),
				}));

				// Obter a data mais recente e a mais antiga na lista
				const minDate = new Date(Math.min(...dateObjects.map((item) => item.date)));
				const maxDate = new Date(Math.max(...dateObjects.map((item) => item.date)));

				// Criar uma lista de todas as datas no intervalo
				const allDates = [];
				for (
					let currentDate = minDate;
					currentDate <= maxDate;
					currentDate.setDate(currentDate.getDate() + 1)
				) {
					allDates.push(new Date(currentDate));
				}

				// Criar um objeto com as datas existentes e suas quantidades correspondentes
				const existingDates = dateObjects.reduce((acc, item) => {
					acc[item.date.toISOString().split('T')[0]] = item.quantity;
					return acc;
				}, {});

				// Preencher as datas faltantes com quantidade zero
				const filledData = allDates.map((date) => ({
					date: date.toISOString().split('T')[0],
					quantity: existingDates[date.toISOString().split('T')[0]] || 0,
				}));

				return {
					series: filledData.map((day) => day.date),
					data: filledData.map((day) => day.quantity),
				};
			}
		},
		[newXFinished, fixedPeriod]
	);

	const handleChangeProduct = (filter) => {
		const filterArr = Object.entries(filter)[0];
		let filters = filtersColumnLineChart;

		setProductFilter(productTypes[Object.values(filter)[0]]);

		if (filterArr.includes('all')) {
			setProductFilter('Todos');
			for (const key in filter) {
				if (Object.hasOwnProperty.call(filter, key) && key === 'product') {
					delete filters[key];
				}
			}
		}
		return filters;
	};

	const handleChangeChannel = (filter) => {
		const filterArr = Object.entries(filter)[0];

		let filters = filtersColumnLineChart;
		setChannelFilter(productTypes[Object.values(filter)[0]]);

		if (filterArr.includes('all')) {
			setChannelFilter('Todos');
			for (const key in filter) {
				if (Object.hasOwnProperty.call(filter, key) && key === 'channel') {
					delete filters[key];
				}
			}
		}
		return filters;
	};

	const handleChangeFiltersCLChart = (filter) => {
		if (!filter) return;
		let filters = filtersColumnLineChart;
		//adicionando novo filtro a lista de filtros pendente
		filters[Object.keys(filter)[0]] = Object.values(filter)[0];
		const filterArr = Object.entries(filter)[0];

		switch (true) {
			case filterArr.includes('channel'):
				filters = handleChangeChannel(filter);
				break;
			case filterArr.includes('product'):
				filters = handleChangeProduct(filter);
				break;
		}

		setFiltersColumnLineChart(filters);
	};

	//UseEffects==========
	useEffect(() => {
		setTimeout(() => refetchNewXFinished(), 100);
	}, [channelFilter, productFilter]);
	useEffect(() => {
		//caso não seja um ano, não será necessário um refetch
		if (!dateFilter.includes('20')) {
			setFixedPeriod(dateFilter);
			return;
		}
		setTimeout(() => refetchNewXFinished(), 100);
	}, [dateFilter]);
	//anim
	useEffect(() => {
		AOS.init({
			startEvent: 'DOMContentLoaded',
			disable: function () {
				var maxWidth = 996;
				return window.innerWidth < maxWidth;
			},
			throttleDelay: 10,
			once: true,
			duration: 700,
			offset: 10,
		});
	});

	return (
		<Col>
			<div className='card'>
				<div className='flex-wrap card-header d-flex justify-content-between'>
					<div className='header-title d-flex align-items-center'>
						<h5 className='mb-0'>Estoque por ano</h5>
						<CustomTooltip
							text={
								<div style={{ textAlign: 'left' }}>
									<p>
										Total de atendimentos gerados comparado com total de atendimentos finalizados
										por período.
									</p>
									<p>* Considera todo o período</p>
								</div>
							}
						/>
					</div>

					<div className='d-flex align-items-center' style={{ zIndex: '9999' }}>
						<Dropdown>
							<b>Produto: </b>
							<Dropdown.Toggle
								as={Button}
								variant='text-gray'
								type='button'
								id='dropdownMenuButtonSM'
							>
								{productFilter}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => handleChangeFiltersCLChart({ product: 'all' })}>
									Todos
								</Dropdown.Item>
								{Object.keys(productTypes).map((productKey) => (
									<Dropdown.Item
										onClick={() => handleChangeFiltersCLChart({ product: productKey })}
									>
										{productTypes[productKey]}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>

						<Dropdown>
							<b>Canal: </b>
							<Dropdown.Toggle
								as={Button}
								variant='text-gray'
								type='button'
								id='dropdownMenuButtonSM'
							>
								{channelFilter}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => handleChangeFiltersCLChart({ channel: 'all' })}>
									Todos
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => (
										handleChangeFiltersCLChart({ channel: 'os' }), setChannelFilter('OS')
									)}
								>
									OS
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => (
										handleChangeFiltersCLChart({ channel: 'chat' }), setChannelFilter('Chat')
									)}
								>
									Chat
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => (
										handleChangeFiltersCLChart({ channel: 'ligacao' }), setChannelFilter('Ligação')
									)}
								>
									Ligação
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>

						<Dropdown>
							<b>Período: </b>
							<Dropdown.Toggle
								as={Button}
								variant='text-gray'
								type='button'
								id='dropdownMenuButtonSM'
							>
								{dateFilter}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item onClick={() => setDateFilter('Semanal')}>Semanal</Dropdown.Item>
								<Dropdown.Item onClick={() => setDateFilter('Mensal')}>Mensal</Dropdown.Item>
								{/* <Dropdown.Item onClick={() => setDateFilter('Anual')}>
                          Anual
                        </Dropdown.Item> */}
								<Dropdown.Item onClick={() => setDateFilter('Últimos anos')}>
									Últimos anos
								</Dropdown.Item>

								<Dropdown.Divider />

								{newXFinished?.totalYears
									?.sort((a, b) => b - a)
									?.map((year) => (
										<Dropdown.Item onClick={() => setDateFilter(`${year}`)}>{year}</Dropdown.Item>
									))}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>

				<div style={{ position: 'relative' }}>
					<div
						className='card-body'
						style={{
							position: 'relative',
							filter: newXFinishedIsFetching ? 'blur(5px)' : 'none',
						}}
					>
						<LazyChart
							debouncedChart={debouncedChart}
							data={{
								monthsAllByMonth: applyDateFilters(
									'last7DaysServicesQuantityByDay',
									'last30DaysServicesQuantityByDay',
									'last12MonthsServicesQuantityByMonth',
									'last3YearsServicesQuantityByYear',
									'servicesQuantityByMonthByYear'
								).data,
								monthsFinishedByMonth: applyDateFilters(
									'last7DaysFinishedServicesQuantityByDay',
									'last30DaysFinishedServicesQuantityByDay',
									'last12MonthsFinishedServicesQuantityByMonth',
									'last3YearsFinishedServicesQuantityByYear',
									'finishedServicesQuantityByMonth'
								).data,
								monthsStockByMonth: applyDateFilters(
									'last7DaysServicesStockByDay',
									'last30DaysServicesStockByDay',
									'last12MonthsServicesStockByMonth',
									'last3YearsServicesStockByYear',
									'stockServicesQuantityByMonthByYear'
								).data,
								monthsAccumulatedByMonth: applyDateFilters(
									'last7DaysAccumulatedServicesQuantityByDay',
									'last30DaysAccumulatedServicesQuantityByDay',
									'last12MonthsAccumulatedServicesQuantityByMonth',
									'last3YearsAccumulatedServicesQuantityByYear',
									'accumulatedServicesQuantityByMonth'
								).data,
								categories: applyDateFilters(
									'last7DaysServicesQuantityByDay',
									'last30DaysServicesQuantityByDay',
									'last12MonthsServicesQuantityByMonth',
									'last3YearsServicesQuantityByYear',
									'servicesQuantityByMonthByYear'
								).series,
							}}
							loadings={{ newXFinishedIsFetching, newXFinishedIsLoading }}
							dateFilters={{ dateFilter, fixedPeriod }}
						/>
					</div>
					{newXFinishedIsFetching ? (
						<Spinner variant='primary' style={{ position: 'absolute', left: '50%', top: '50%' }} />
					) : null}
				</div>
			</div>
		</Col>
	);
});

const LazyChart = ({ debouncedChart, data, loadings, dateFilters }) =>
	useMemo(
		() => (
			<ReactPlaceholder
				type='rect'
				style={{ height: '300px' }}
				ready={!loadings.newXFinishedIsFetching || !loadings.newXFinishedIsLoading}
				showLoadingAnimation={true}
				color='#E0E0E0'
			>
				<Chart
					options={
						columnLineData(
							[
								{
									name: 'Estoque',
									type: 'column',
									data: data.monthsStockByMonth,
								},
								{
									name: 'Acumulado',
									type: 'column',
									data: data.monthsAccumulatedByMonth,
								},
								{
									name: 'Novos',
									type: 'line',
									data: data.monthsAllByMonth,
								},
								{
									name: 'Finalizados',
									type: 'line',
									data: data.monthsFinishedByMonth,
								},
							],
							data.categories,
							chartTypes[dateFilters.dateFilter]
						).options
					}
					series={
						columnLineData(
							[
								{
									name: 'Estoque',
									type: 'column',
									data: data.monthsStockByMonth,
								},
								{
									name: 'Acumulado',
									type: 'column',
									data: data.monthsAccumulatedByMonth,
								},
								{
									name: 'Novos',
									type: 'line',
									data: data.monthsAllByMonth,
								},
								{
									name: 'Finalizados',
									type: 'line',
									data: data.monthsFinishedByMonth,
								},
							],
							data.categories,
							chartTypes[dateFilters.dateFilter]
						).series
					}
					type='area'
					height='300'
				/>
			</ReactPlaceholder>
		),
		[debouncedChart, dateFilters.fixedPeriod]
	);

export default StockByYear;
