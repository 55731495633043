import React, { useEffect, useState, useDeferredValue } from 'react';
import { api } from '../../api/api';
//hooks
import { useQuery, useQueryClient } from 'react-query';
import { Modal, Button, Badge, Dropdown, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { useSessionStorage } from '@uidotdev/usehooks';
import { usePagination } from '@table-library/react-table-library/pagination';
import { useTheme } from '@table-library/react-table-library/theme';
//pseudotypes
import { osStatusTypes, osStatusColors } from '../../utils/types/osStatusTypes';
//styles
import { TableWrapper } from './style';
import { IoFolderOpenOutline } from 'react-icons/io5';
import { CiCircleCheck } from 'react-icons/ci';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { FiFileText } from 'react-icons/fi';
//components
import ReactPlaceholder from 'react-placeholder';
import CardWithGrowth from '../cardWithGrowth/CardWithGrowth';
import {
	Table,
	Header,
	HeaderRow,
	Body,
	Row,
	HeaderCell,
	Cell,
} from '@table-library/react-table-library/table';
import { VerticalLine } from '../verticalLine/verticalLine';
import { ArrowExpansion } from '../table/arrowExpansion';
import ChartFilters from '../chartFilters/chartFilters';
import useFormattedDate from '../../hooks/useFormattedDate';
// import { productTypes } from '../../utils/types/productTypes';
import { Skeleton } from '../table/skeleton';
import { ServerPagination } from '../table/serverPagination';
import SearchboxServer from '../searchboxServer/searchboxServer';

const statusTypes = {
	finalizado: 'Finalizado',
	desenvolvendo: 'Em andamento',
	backlog: 'Backlog',
	paralisado: 'Paralisado',
};

const OSDetails = ({ show, dev, setShowDevDetails, totalYears, year }) => {
	const queryClient = useQueryClient();
	const [isLoading, setLoading] = useSessionStorage('isLoading', false);
	const { setFormattedDate } = useFormattedDate();

	const [search, setSearch] = useState();
	const [dataTable, setDataTable] = useState();
	const deferredDataTable = useDeferredValue(dataTable);
	const limitTable = 5;
	const [idsShowDesc, setIdsShowDesc] = useState([]);

	const [osStatus, setOsStatus] = useState();
	const [statusQueryMethods, setStatusQueryMethods] = useState();
	const [startDateDisplay, setStartDateDisplay] = useState();
	const [endDateDisplay, setEndDateDisplay] = useState();

	const [actualPage, setPagination] = useState(1);
	const [productFilter, setProductFilter] = useState('Todos');
	const [productTypes, setProductTypes] = useState({});
	const [filtersController, setFiltersController] = useState({});

	const [status, setStatus] = useState('finalizado');

	const {
		data: devDetails,
		refetch: refetchDevDetail,
		isLoading: isLoadingDevsDetails,
		isFetching: isFetchingDevsDetails,
		isRefetching: isRefetchingDevsDetails,
	} = useQuery(['devsDetail', dev?.id ?? 0], ({ signal }) => getUser(signal), {
		onError: (err) => console.log(err),
		// onSettled: () => setLoading(false),
		enabled: !!endDateDisplay,
	});

	const { data: productTypesVar, error } = useQuery('productTypesOsDet', async () => {
		const response = await api.get('/products');
		setProductTypes(response.data);
	});

	const getUser = async (signal) => {
		if (!dev?.id || !startDateDisplay || !endDateDisplay) return;

		let filters = '';
		if (filtersController) {
			//convertendo objeto em string params para requisição
			for (let key in filtersController) {
				if (filtersController.hasOwnProperty(key)) {
					filters += `${key}=${filtersController[key]}&`;
				}
			}
		}
		try {
			const response = await api.get(
				`/devs/os/details/${dev?.id}?status=${
					status ?? ''
				}&limit=${limitTable}&page=${actualPage}&search=${search}&${filters}initialDate=${
					new Date(startDateDisplay).toISOString().split('T')[0]
				}&finalDate=${new Date(endDateDisplay).toISOString().split('T')[0]}`,
				{ signal }
			);

			console.log(response);
			setLoading(false);
			setDataTable({ nodes: response?.data.reverse() });
			return response?.data;
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const handleCancelRequest = () => {
		console.log('cancel request');
		queryClient.cancelQueries([`devsDetail`]);
	};

	const handleSetIdsChart = (row) => {
		if (idsShowDesc.includes(row)) {
			setIdsShowDesc(idsShowDesc.filter((id) => id !== row));
		} else {
			setIdsShowDesc(idsShowDesc.concat(row));
		}
	};
	const handleRefetchDevDetail = async () => {
		handleCancelRequest();
		setDataTable({ nodes: [] });
		await new Promise((resolve) => setTimeout(resolve, 0)); // "Aguarda" os estados serem atualizados antes
		setTimeout(() => refetchDevDetail(), 100);
	};

	const handleSetPagination = (num) => {
		setPagination(num);
		handleRefetchDevDetail();
	};

	const handleStatus = (status) => {
		setStatus(status);
		handleRefetchDevDetail();
	};

	useEffect(() => {
		if (!show) {
			handleCancelRequest();
			return;
		}
		setOsStatus();
		handleRefetchDevDetail();
	}, [show]);

	useEffect(() => {
		handleRefetchDevDetail();
	}, [search]);

	const pagination = usePagination(deferredDataTable, {
		state: {
			page: 0,
			size: limitTable,
		},
	});
	const theme = useTheme({
		BaseCell: `
			&:nth-of-type(1) {
				left: 0px;
			}`,
	});

	return (
		<Modal
			show={show}
			className='back-blur'
			onHide={() => setShowDevDetails(false)}
			dialogClassName='w-max-content'
		>
			<Modal.Header closeButton>
				<Modal.Title>{dev?.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body className='d-flex flex-column gap-2 overflow-hidden'>
				<div className='w-100 d-flex flex-wrap justify-content-around'>
					<SearchboxServer setSearch={setSearch} />
					<div className='d-flex align-items-center justify-content-center'>
						{/* <Dropdown>
							<b>Produto: </b>
							<Dropdown.Toggle
								as={Button}
								variant='text-gray'
								type='button'
								id='dropdownMenuButtonSM'
							>
								{productFilter || 'Todos'}
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
								<Dropdown.Item onClick={() => handleChangeServicesFilters({ product: 'all' })}>
									Todos
								</Dropdown.Item>
								{Object.keys(productTypes).map((productKey) => (
									<Dropdown.Item
										onClick={() => handleChangeServicesFilters({ product: productKey })}
									>
										{productTypes[productKey]}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown> */}
						<Dropdown>
							<b>Status: </b>
							<Dropdown.Toggle
								as={Button}
								variant='text-gray'
								type='button'
								id='dropdownMenuButtonSM'
							>
								{statusTypes[status] || 'Todos'}
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ maxHeight: '300px', overflowY: 'auto' }}>
								<Dropdown.Item onClick={() => handleStatus()}>Todos</Dropdown.Item>
								<Dropdown.Item onClick={() => handleStatus('finalizado')}>Finalizado</Dropdown.Item>
								<Dropdown.Item onClick={() => handleStatus('desenvolvendo')}>
									Em andamento
								</Dropdown.Item>
								<Dropdown.Item onClick={() => handleStatus('paralisado')}>Paralisado</Dropdown.Item>
								<Dropdown.Item onClick={() => handleStatus('backlog')}>Backlog</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						{dev && (
							<ChartFilters
								period
								product
								dates={totalYears.map((year) => Number(year))}
								data={'osStatus'}
								productFilter={productFilter}
								setProduct={setProductFilter}
								filtersController={filtersController}
								setFiltersController={setFiltersController}
								order={['product', 'period']}
								setData={setOsStatus}
								setQueryMethods={setStatusQueryMethods}
								startDateDisplay={new Date(year, 0, 1)}
								endDateDisplay={new Date(year, 11, 31)}
								setStartDateDisplay={setStartDateDisplay}
								setEndDateDisplay={setEndDateDisplay}
								onChange={handleRefetchDevDetail}
								query={`/devs/os/details/${dev?.id}/overview`}
							/>
						)}
					</div>
				</div>

				<main className='d-flex flex-wrap gap-4'>
					<section className='w-100' style={{ flex: '4 500px', minWidth: '40vw' }}>
						<div className='position-relative'>
							{isFetchingDevsDetails &&
								!isRefetchingDevsDetails &&
								(devDetails || []).length === 0 && <Skeleton fieldQuantity={7} isActive />}

							{Array.isArray(deferredDataTable?.nodes) && (
								<>
									<TableWrapper>
										<Table
											id='table-dev-os'
											responsive
											data={deferredDataTable}
											pagination={pagination}
											// sort={sort}
											theme={theme}
											className='table'
											role='grid'
											data-toggle='data-table'
										>
											{(tableList) => (
												<>
													<Header>
														<HeaderRow>
															<HeaderCell></HeaderCell>
															<HeaderCell sortKey='os'>OS</HeaderCell>
															<HeaderCell sortKey='gerado'>Data gerado</HeaderCell>
															<HeaderCell sortKey='atribuido'>Atribuído</HeaderCell>
															<HeaderCell sortKey='iniciado'>Iniciado</HeaderCell>
															<HeaderCell sortKey='finalizada'>Finalizada</HeaderCell>
															<HeaderCell sortKey='planejado'>Planejado</HeaderCell>
															<HeaderCell sortKey='executado'>Executado</HeaderCell>
															<HeaderCell></HeaderCell>
														</HeaderRow>
													</Header>
													<Body>
														{tableList.map((os, index) => (
															<>
																<Row
																	className='pointer'
																	item={os}
																	onClick={() => handleSetIdsChart(index)}
																>
																	<Cell
																		style={{
																			borderLeft: idsShowDesc.includes(os.id)
																				? `4px solid var(--bs-${osStatusColors[os.status]})`
																				: 'none',
																			borderRadius: idsShowDesc.includes(os.id)
																				? '5px 0 0 0'
																				: 'inherit',
																		}}
																	>
																		<OverlayTrigger
																			placement='top'
																			overlay={<Tooltip>{osStatusTypes[os.status] || ''}</Tooltip>}
																		>
																			<Badge
																				pill
																				bg={osStatusColors[os.status]}
																				className={`text-${osStatusColors[os.status]}`}
																			>
																				.
																			</Badge>
																		</OverlayTrigger>
																	</Cell>
																	<Cell>{os.id}</Cell>
																	<Cell>{setFormattedDate(os.data_atendimento)}</Cell>
																	<Cell>{setFormattedDate(os.data_backlog_dev)}</Cell>
																	<Cell>{setFormattedDate(os.data_inicio_dev)}</Cell>
																	<Cell>{setFormattedDate(os.data_fim_dev)}</Cell>
																	<Cell>{os.tempo_planejamento || 0} mins</Cell>
																	<Cell>{os.tempo_executado || 0} mins</Cell>
																	<Cell>
																		<ArrowExpansion active={idsShowDesc.includes(index)} />
																	</Cell>
																</Row>
																{idsShowDesc.includes(index) && (
																	<tr
																		key={os.id}
																		className='d-flex'
																		pinLeft
																		style={{ gridColumn: '1/-1' }}
																	>
																		<Cell
																			pinLeft
																			style={{
																				borderLeft: `4px solid var(--bs-${
																					osStatusColors[os.status]
																				})`,
																				borderRadius: '0 0 0 5px',
																			}}
																		>
																			{os.descricao}
																		</Cell>
																	</tr>
																)}
															</>
														))}
													</Body>
												</>
											)}
										</Table>
									</TableWrapper>

									{(isRefetchingDevsDetails || isFetchingDevsDetails || isLoadingDevsDetails) && (
										<div
											className='h-100 w-100 position-absolute top-0'
											style={{ backgroundColor: '#1c1f3126' }}
										>
											<Spinner variant='primary' className='position-absolute top-50 start-50' />
										</div>
									)}
									{deferredDataTable?.nodes.length === 0 &&
										!isFetchingDevsDetails &&
										!isRefetchingDevsDetails && (
											<div className='mb-4 mt-4 text-center'>Não há dados</div>
										)}
								</>
							)}
						</div>
						<div className='d-flex flex-wrap gap-2'>
							{Array.isArray(deferredDataTable?.nodes) && !statusQueryMethods?.isFetching && (
								<ServerPagination
									data={deferredDataTable || { nodes: [] }}
									pagination={pagination}
									totalData={devDetails?.length > 0 ? devDetails[0]?.total : 0}
									actualPage={actualPage}
									setPagination={handleSetPagination}
								/>
							)}
							<b className='d-flex align-items-center gap-1'>
								Total:{' '}
								<ReactPlaceholder
									type='text'
									rows={1}
									ready={!isFetchingDevsDetails || false}
									showLoadingAnimation={true}
									style={{ width: '80px' }}
									color='#E0E0E0'
								>
									{devDetails?.length > 0 ? devDetails[0]?.total : 0}
								</ReactPlaceholder>
							</b>
						</div>
					</section>
					<VerticalLine />
					<section style={{ flex: '1' }}>
						<CardWithGrowth
							style={{ margin: '0', boxShadow: 'none' }}
							title='Total planejado'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>Total de tempo planejado em minutos</p>
								</div>
							}
							isLoading={isLoading}
							colorIcon={'secondary'}
							icon={<IoFolderOpenOutline size={20} />}
						>
							<CardWithGrowth.Body isLoading={statusQueryMethods?.isFetching}>
								{osStatus?.total_planejado}
							</CardWithGrowth.Body>
						</CardWithGrowth>
						<CardWithGrowth
							style={{ margin: '0', boxShadow: 'none' }}
							title='Total executado'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>Total de tempo efetivamente usado para desenvolvimento em minutos</p>
								</div>
							}
							isLoading={isLoading}
							colorIcon={'success'}
							icon={<CiCircleCheck size={20} />}
						>
							<CardWithGrowth.Body isLoading={statusQueryMethods?.isFetching}>
								{Math.round(osStatus?.total_executado) || 0}
							</CardWithGrowth.Body>
						</CardWithGrowth>
						<CardWithGrowth
							style={{ margin: '0', boxShadow: 'none' }}
							title='Paralisado'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>O total OS's paralisadas</p>
								</div>
							}
							isLoading={isLoading}
							colorIcon={'danger'}
							icon={<IoIosCloseCircleOutline size={20} />}
						>
							<CardWithGrowth.Body isLoading={statusQueryMethods?.isFetching}>
								{osStatus?.paralisados}
							</CardWithGrowth.Body>
						</CardWithGrowth>
						<CardWithGrowth
							style={{ margin: '0', boxShadow: 'none' }}
							title='Em andamento'
							tooltip={
								<div style={{ textAlign: 'left' }}>
									<p>O total de OS's em andamento</p>
								</div>
							}
							isLoading={isLoading}
							colorIcon={'warning'}
							icon={<FiFileText size={20} />}
						>
							<CardWithGrowth.Body isLoading={statusQueryMethods?.isFetching}>
								{Number(osStatus?.em_andamento ?? 0) || 0}
							</CardWithGrowth.Body>
						</CardWithGrowth>
					</section>
				</main>
			</Modal.Body>
		</Modal>
	);
};

export default OSDetails;
